<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap v-if="disciplineList">
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Disciplinary Actions</span>
          <v-spacer></v-spacer>

          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-5>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="40%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: kumbhBold"
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Disciplinary Action
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      pa-4
                      text-left
                      style="background: #005f32 !important"
                    >
                      <span
                        class="kumbhBold"
                        style="color: #ffffff; font-size: 20px"
                        >Add Disciplinary Action</span
                      >
                    </v-flex>
                    <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                      <!-- <v-select
                        v-model="employee"
                        :items="employeeList"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                        :rules="[rules.required]"
                        required
                        hide-details
                        label="Choose Employee"
                      >
                      </v-select> -->

                      <v-autocomplete
                        v-model="employee"
                        :items="employeeList"
                        item-text="name"
                        :search-input.sync="search"
                        item-value="_id"
                        outlined
                        class="rounded-xl"
                        hide-details
                        clearable
                        label="Employee"
                        dense
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 align-self-center text-left px-5 pa-2>
                      <v-textarea
                        v-model="disciplinaryAction"
                        label="Disciplinary Action"
                        :rules="[rules.required]"
                        required
                        outlined
                        dense
                      ></v-textarea>
                    </v-flex>
                  </v-layout>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="error"
                      tile
                      outlined
                      @click="dialogclose(), (disciplinaryAction = null)"
                    >
                      Close
                    </v-btn>
                    &nbsp;
                    <v-btn
                      color="#005f32"
                      dark
                      tile
                      :disabled="!addcat"
                      @click="addDiscpline()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      class="rounded-xl"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu11 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      hide-details
                      dense
                      class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
<!-- filter -->
              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <!-- <v-select
                  v-model="employeeid"
                  :items="employeeList"
                  item-text="name"
                  item-value="_id"
                  outlined
                  clearable
                  dense
                  :rules="[rules.required]"
                  required
                  hide-details
                  class="rounded-xl"
                  label="Employee"
                >
                </v-select> -->

                <v-autocomplete
                  v-model="employeeid"
                  :items="employeeList1"
                  item-text="name"
                  :search-input.sync="search1"
                  item-value="_id"
                  outlined
                  class="rounded-xl"
                  hide-details
                  clearable
                  label="Employee"
                  dense
                ></v-autocomplete>
              </v-flex>
              <v-flex xs2 align-self-center text-right pb-2 sm3 px-5>
                <span
                  v-if="!moreFilter"
                  @click="moreFilter = true"
                  style="font-size: 14px; cursor: pointer"
                  class="kumbhMedium"
                >
                  <v-icon>mdi-filter-variant-plus</v-icon> More filters..</span
                >
                <span
                  v-if="moreFilter"
                  @click="
                    (moreFilter = false), (project = null), (department = null)
                  "
                  style="font-size: 14px; cursor: pointer"
                  class="kumbhMedium"
                  ><v-icon>mdi-filter-variant-minus</v-icon>Show less</span
                >
              </v-flex>

              <v-flex xl3 lg3 md3 py-2 sm3 xs12 px-2 v-if="moreFilter">
                <v-select
                  v-model="department"
                  :items="departmentList"
                  item-text="deptName"
                  item-value="_id"
                  outlined
                  class="rounded-xl"
                  dense
                  clearable
                  label="Choose Department"
                >
                </v-select>
              </v-flex>
              <v-flex xl3 lg3 md3 py-2 sm3 xs12 px-2 pr-3 v-if="moreFilter">
                <v-select
                  v-model="project"
                  :items="projectList"
                  item-text="projectName"
                  item-value="_id"
                  outlined
                  class="rounded-xl"
                  dense
                  clearable
                  label="Choose Project"
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap v-if="disciplineList">
          <v-flex xs12 align-self-center v-if="disciplineList.length > 0">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr class="kumbhMedium">
                    <th class="text-left">Date</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Disciplinary Action</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in disciplineList" :key="i">
                    <td class="text-left">
                      <span v-if="item.create_date">
                        <span
                          style="font-family: kumbhRegular"
                          v-if="item.create_date"
                          >{{ item.create_date.slice(0, 10) }}</span
                        >
                      </span>
                    </td>
                    <td class="text-left">
                      <span v-if="item.employeeid">
                        <span
                          style="font-family: kumbhRegular"
                          v-if="item.employeeid.name"
                          >{{ item.employeeid.name }}</span
                        >
                      </span>
                    </td>
                    <td class="text-left">
                      <span
                        v-if="item.disciplinaryAction"
                        style="font-family: kumbhRegular"
                        >{{ item.disciplinaryAction.slice(0, 20) }}

                        <br />
                        <v-dialog v-model="item.view" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              style="font-family: kumbhBold"
                              class="text-none"
                              text
                              plain
                              :ripple="false"
                              depressed
                              dark
                              title="View more"
                              v-on="on"
                              v-bind="attrs"
                              color="#005f32"
                            >
                              <span class="vhover"> View </span>
                            </v-btn>
                          </template>
                          <v-card class="pa-4 pt-8">
                            <v-card-text>
                              <span class="kumbhRegular">
                                {{ item.disciplinaryAction }}
                              </span>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </span>
                    </td>
                    <td class="text-left">
                      <v-layout wrap>
                        <v-flex xs12 sm6 md4 lg3 align-self-center pa-2>
                          <v-dialog
                            persistent
                            v-model="item.delete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                style="font-family: kumbhBold"
                                class="mr-2"
                                fab
                                outlined
                                depressed
                                dark
                                title="Delete"
                                v-on="on"
                                v-bind="attrs"
                                color="#005f32"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                  text-left
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Delete Confirmation</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-card-title
                                >Are you sure you want to delete this
                                Disciplinary action?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="error"
                                  tile
                                  dark
                                  @click="item.delete = false"
                                  >Cancel</v-btn
                                >
                                &nbsp;
                                <v-btn
                                  color="#005f32"
                                  outlined
                                  tile
                                  @click="deleteItem(item)"
                                  >Delete</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                        <v-flex xs12 sm6 md4 lg3 align-self-center pa-2>
                          <v-btn
                            color="#005f32"
                            style="font-family: kumbhBold"
                            small
                            dark
                            outlined
                            title="Edit"
                            depressed
                            fab
                            @click="editcat(item)"
                          >
                            <v-icon>mdi-pen</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
          <v-flex xs12 align-self-center pa-12 v-else>
            <span class="kumbhRegular">No data found!</span>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start v-else>
          <v-flex xs12 align-self-center pa-12>
            <span class="kumbhRegular">No data found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-dialog persistent v-model="editdialog" max-width="40%">
      <v-card tile>
        <v-layout wrap>
          <v-flex
            xs12
            pa-4
            text-left
            align-self-center
            style="background: #005f32 !important"
          >
            <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
              >Edit Disciplinary Action</span
            >
          </v-flex>
          <v-flex xs12 pa-2 px-5 pt-8 text-left v-if="editingitem.employeeid">
            <v-text-field
              v-model="editingitem.employeeid.name"
              label="Employee"
              :rules="[rules.required]"
              outlined
              readonly
              dense
              disabled
              hide-details
              required
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 px-5 pa-2 text-left>
            <v-textarea
              v-model="editingitem.disciplinaryAction"
              label="Disciplinary Action"
              :rules="[rules.required]"
              outlined
              dense
              required
            >
            </v-textarea>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            tile
            outlined
            @click="(editdialog = false), getData()"
          >
            Close
          </v-btn>
          &nbsp;
          <v-btn
            v-if="!g"
            color="#005f32"
            dark
            tile
            @click="edit(editingitem._id)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      disciplineList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      disciplinaryAction: null,
      employeeList: null,
      employeeList1: null,
      employee: null,
      employeeid: null,

      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      search: null,
      search1: null,
      departmentList: null,
      projectList: null,
      moreFilter: false,
      department: null,
      project: null,
    };
  },
  watch: {
    search(val) {
      this.loading = true;
      setTimeout(() => {
        this.getEmps(val);
        this.loading = false;
      }, 500);
    },

    search1(val) {
      this.loading = true;
      setTimeout(() => {
        this.getEmps1(val);
        this.loading = false;
      }, 500);
    },

    currentPage() {
      this.getData();
    },
    employeeid() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },

    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.getData();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    project() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },

    department() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    employee(){
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
  },
  components: {
    // ImageComp,
  },
  beforeMount() {
    this.getDept();
    
    this.getProject();
    if (this.fromDate) {
      // this.fromDate.setDate(this.fromDate.getDate());
      this.fromDate.setDate(this.fromDate.getDate() - 31);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      // this.toDate = null;
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
    this.getEmps();
    this.getEmps1();
    const empIdFromQuery = this.$route.query.employeeId; // Get employeeId from query
  if (empIdFromQuery) {
    this.employeeid = empIdFromQuery; // Set the filter value
    this.getEmps1(""); // Fetch employee list to ensure the name is available
    this.getData(); // Fetch disciplinary actions for this employee
  }
  },

  methods: {
    getDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.departmentList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProject() {
      this.appLoading = true;
      axios({
        url: "/projects/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.projectList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getEmps(val) {
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: val,
          except: localStorage.getItem("userid"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getEmps1(val) {
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: val,
          except: localStorage.getItem("userid"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList1 = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },


    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/disciplinaryAction/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/disciplinaryAction/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 20,
          employeeid: this.employeeid,
          from: this.fromDate,
          to: this.toDate,
          project: this.project,
          department: this.department,
          keyword:this.val
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.disciplineList = response.data.data;
            this.totalData = response.data.totalLength;
            this.pages = Math.ceil(this.totalData / response.data.limit);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addDiscpline() {
      var data = {};
      data["disciplinaryAction"] = this.disciplinaryAction;
      data["employeeid"] = this.employee;
      axios({
        url: "/disciplinaryAction/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.disciplinaryAction = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["disciplinaryAction"] = this.editingitem.disciplinaryAction;
      axios({
        url: "/disciplinaryAction/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
